const EditSingleStyles = {
  boxContainer: {
    flexGrow: 1,
    padding: { sx: 0, md: 5 },
    fontSize: "12px",
  },
  typoLabel: { mb: 1, fontFamily: "Montserrat" },
  radioLabels: { ml: 2, fontFamily: "Montserrat" },
  gridAlign: { textAlign: "left" },
  checkboxLabelMargin: { m: 1 },
  labelBelowMargin: { mb: 2 },
  checkBoxStyle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    ml: 0,
    fontFamily: "Montserrat",
  },
  previewDiagramsStyle: {
    height: "fit-content",
    position: "relative",
    border: "1px solid #ccc",
    p: 2,
    borderRadius: "10px",
    gap: "auto",
  },
  previewUploadStyle: {
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    bgcolor: "#1976d2",
  },
  diagramModalBoxStyles: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "90vh",
    bgcolor: "background.paper",
    boxShadow:
      "0px -48px 100px 0px rgba(17, 12, 46, 0.15), 0px 48px 100px 0px rgba(17, 12, 46, 0.15)",
    p: "20px",
    pt: "10px",
    borderRadius: "5px",
  },
  digramModalTitleWithCrossStyles: {
    mb: 2,
    pb: 1,
    borderBottom: "1px solid #ccc",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filePreviewsModal: {
    height: "90%",
    width: "100%",
    overflowY: "auto",
  },
  diagramViewStyle: {
    maxHeight: "200px",
    position: "relative",
    p: 1,
  },
  errorUiStyles: {
    padding: 5,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    width: "fit-content",
    mx: "auto",
  },
  bgcolor: "red",
  wholePaperPadding: { padding: 2 },
  mainTitle: { p: { xs: 3, md: 0 } },
  firstGrid: { p: { xs: "0px", md: "15px" } },
  checkboxLabelStyle: { ml: 0 },
  firstGridSub1: {
    "& .Mui-error": { mx: 0 },
  },
  videoTextFieldStyle: { mb: 1, width: "100%" },
  previewGridStyles: {
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  previewGridInlineStyle: { marginBottom: 0 },
  previewDeleteIconStyle: { fontSize: "14px" },
  uploadCloudIconStyle: { color: "white", fontSize: "28px" },
  previewAndUploadTextStyle: {
    fontSize: { xs: "0.8rem", sm: "1rem" },
    width: "70%",
    color: "white",
    textAlign: "center",
  },
  equipmentCheckboxList: {
    fontFamily: "Montserrat",
    fontSize: "12px ",
  },
  equipmentCheckboxListCheckBox: { marginRight: 8 },
  dialogBoxHeight: { height: "120px" },
  diagramModalCloseIconStyle: { color: "black" },
  renderDiagramStyle: {
    height: "fit-content",
    mt: 1,
  },
  diagramModalActivityTitleStyle: { my: 2 },
  renderDiagramDeleteIconStyle: { fontSize: "20px" },
  singleFileUploadContainer: {
    height: "fit-content",
    width: "100%",
  },
  singleFileUploadContainerInlineStyle: { paddingInline: 0 },
  chooseFileTextStyle: { fontFamily: "Montserrat" },
  uploadedDiagramMainContainerStyle: {
    height: "fit-content",
  },
  uploadedPreviewGridStyle: {
    maxHeight: "250px",
    position: "relative",
    p: 1,
  },
  uploadImagePreviewDeleteIconStyle: { fontSize: "20px" },
  ErrorUiGoBackStyke: { marginTop: "10px" },
};
export default EditSingleStyles;
