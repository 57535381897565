export enum IActivityMasterKeys {
  type = "type",
  subType = "subType",
  gradeBands = "gradeBands",
  numberOfPlayers = "numberOfPlayers",
  spaceRequirement = "spaceRequirement",
  progressionLevel = "progressionLevel",
  skillsAndConcepts = "skillsAndConcepts",
  standardsAlignments = "standardsAlignments",
  selCompetencies = "selCompetencies",
  equipment = "equipment",
}
export enum IActivityKeys {
  name = "name",
  isFreeSample = "isFreeSample",
  subStandardsAlignments = "subStandardsAlignments",
  videoEnglish = "videoEnglish",
  videoSpanish = "videoSpanish",
  diagram = "diagram",
  inclusionGuideCrosslink = "inclusionGuideCrosslink",
  hasSpanishTranslation = "hasSpanishTranslation",
  isEquipmentRequired = "isEquipmentRequired",
  type = "type",
  subType = "subType",
  gradeBands = "gradeBands",
  numberOfPlayers = "numberOfPlayers",
  spaceRequirement = "spaceRequirement",
  progressionLevel = "progressionLevel",
  skillsAndConcepts = "skillsAndConcepts",
  standardsAlignments = "standardsAlignments",
  selCompetencies = "selCompetencies",
  equipment = "equipment",
}
export enum IActivityTabKeys {
  organization = "organization",
  equipmentDescription = "equipmentDescription",
  instructions = "instructions",
  teachingSuggestions = "teachingSuggestions",
  variations = "variations",
  differentiation = "differentiation",
  selReinforcement = "selReinforcement",
  inclusionAdaptation = "inclusionAdaptation",
  virtualAdaption = "virtualAdaption",
}

export default {
  IActivityMasterKeys,
  IActivityKeys,
};
