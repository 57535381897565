import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Modal,
  Stack,
  ToggleButton,
  Typography,
} from "@mui/material";
import SingleFileUploader from "../file-upload/single-file-upload ";
import "./create-edit-single-activity.scss";
import EditSingleStyles from "../../../styles/editSingleActivity";
import { IActivityKeys } from "../../../enums/ActivityKeysEnum";

export function ConfirmationDialog({
  isConfirmModalOpen,
  handleClose,
}: {
  isConfirmModalOpen: boolean;
  handleClose: (res: boolean) => void;
}) {
  return (
    <Dialog
      open={isConfirmModalOpen}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={150}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={EditSingleStyles.dialogBoxHeight}
      >
        Are you sure you want to delete this diagram ?
      </DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose(false)} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(true)}
          variant="contained"
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function DigramModal({
  renderDiagrams,
  isModalOpen,
  handleClose,
  handleValueChange,
  uploadedDataWithPreview,
  handleUploadedDataWithPreviews,
}: {
  isModalOpen: boolean;
  handleClose: () => void;
  handleValueChange: (key: IActivityKeys, remaingImages: string[]) => void;
  renderDiagrams: string[];
  uploadedDataWithPreview: IUploadedPreview[];
  handleUploadedDataWithPreviews: (data: IUploadedPreview[]) => void;
}) {
  const [diagramState, setDiagramState] = useState<{
    isConfirmToDeleteModalOpen: boolean;
    currentIndex: number | null;
  }>({
    isConfirmToDeleteModalOpen: false,
    currentIndex: null,
  });

  const handelConfirmModalOpen = (indexTab: number) => {
    setDiagramState((prev) => ({
      ...prev,
      isConfirmToDeleteModalOpen: true,
      currentIndex: indexTab,
    }));
  };

  const handleConfirmToDeleteModalClose = (res: boolean) => {
    if (res && diagramState.currentIndex !== null) {
      const remaingImages = renderDiagrams.filter(
        (_, index) => index !== diagramState.currentIndex,
      );
      handleValueChange(IActivityKeys.diagram, remaingImages);
    }

    setDiagramState((prev) => ({
      ...prev,
      isConfirmToDeleteModalOpen: false,
      currentIndex: null,
    }));
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={EditSingleStyles.diagramModalBoxStyles}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={EditSingleStyles.digramModalTitleWithCrossStyles}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Diagrams
          </Typography>
          <ToggleButton
            size="small"
            value="check"
            color="info"
            selected={false}
            onClick={handleClose}
          >
            <CloseIcon sx={EditSingleStyles.diagramModalCloseIconStyle} />
          </ToggleButton>
        </Stack>

        <Grid item xs={12} sx={EditSingleStyles.filePreviewsModal}>
          <SingleFileUploader
            isFileUploaderToOpen={Boolean(
              !renderDiagrams.length && !uploadedDataWithPreview.length,
            )}
            uploadedDataWithPreview={uploadedDataWithPreview}
            onFileUpload={handleUploadedDataWithPreviews}
          />
          {renderDiagrams.length > 0 && (
            <Grid
              container
              sx={EditSingleStyles.renderDiagramStyle}
              justifyContent="flex-start"
            >
              <Typography
                variant="h6"
                width="100%"
                color="textPrimary"
                sx={EditSingleStyles.diagramModalActivityTitleStyle}
              >
                Activity Diagrams
              </Typography>
              {renderDiagrams.map(
                (item: unknown, itemIndex: number) =>
                  typeof item === "string" && (
                    <Grid
                      key={item}
                      item
                      xs={12}
                      md={3}
                      sx={EditSingleStyles.diagramViewStyle}
                    >
                      <img
                        src={item}
                        alt="Activity Diagram"
                        className="diagram-modal-previews"
                      />
                      <ToggleButton
                        size="small"
                        value="check"
                        color="error"
                        className="render-diagram-delete-icon"
                        selected={false}
                        onChange={() => {
                          handelConfirmModalOpen(itemIndex);
                        }}
                      >
                        <DeleteIcon
                          sx={EditSingleStyles.renderDiagramDeleteIconStyle}
                        />
                      </ToggleButton>
                    </Grid>
                  ),
              )}

              <ConfirmationDialog
                isConfirmModalOpen={diagramState.isConfirmToDeleteModalOpen}
                handleClose={handleConfirmToDeleteModalClose}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
}
