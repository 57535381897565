const nameRegex = /^(?!\d+$).+/;
const nameRegex2 = /^(?=.*[A-Za-z]).*$/;
const inclusionGCLinkRegex =
  /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(:\d+)?(\/[^\s]*)?$/i;
const videoInputRegex = /^\d+$/;
const allSpecialChars = /^(?![^a-zA-Z0-9]+$).*/;
const emptyCommaRegex = /,{2,}/;
const subStandardsRegex = /(^,)|(,$)|(?:,{2,})|(?:,\s*,)/;

export const validateName = (value: string) => {
  if (!value) {
    return {
      isValid: false,
      validationErrorMsg: "Activity Name is required.",
    };
  }
  if (!nameRegex.test(value)) {
    return {
      isValid: false,
      validationErrorMsg: "Activity Name can not contain only numbers.",
    };
  }
  if (!allSpecialChars.test(value)) {
    return {
      isValid: false,
      validationErrorMsg:
        "Activity Name can not contain only Special Characters.",
    };
  }
  if (!nameRegex2.test(value)) {
    return {
      isValid: false,
      validationErrorMsg:
        "Activity Name can not contain only numbers and Special Characters.",
    };
  }
  return { isValid: true };
};

export const validateVideoData = (value: string) => {
  return {
    isValid: !value ? true : videoInputRegex.test(value),
  };
};

export const validateInclusionGuideCrosslink = (value: string) => {
  return {
    isValid: !value ? true : inclusionGCLinkRegex.test(value),
  };
};

export const validateSubStandards = (value: string) => {
  if (emptyCommaRegex.test(value)) {
    return {
      isValid: false,
      validationErrorMsg: "Empty commas are not allowed",
    };
  }
  if (value) {
    if (subStandardsRegex.test(value)) {
      return {
        isValid: false,
        validationErrorMsg: "Empty commas with space are not allowed",
      };
    }
  }
  return {
    isValid: true,
  };
};
