import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SvgIcon from "@mui/material/SvgIcon";
import { visuallyHidden } from "@mui/utils";
import { useNavigate } from "react-router-dom";
import { getAllActivitiesWithPagination } from "../../APIs/activitiesApi";
import AlertContext from "../../context/alertContext";
import LoaderContext from "../../context/loaderContext";
import ILocalStorageEnum from "../../enums/LocalStorageEnum";

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "_id",
    numeric: false,
    disablePadding: true,
    label: "Id",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "gradeBands",
    numeric: true,
    disablePadding: false,
    label: "Grade Bands",
  },
  {
    id: "type",
    numeric: true,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "subType",
    numeric: true,
    disablePadding: false,
    label: "Sub-Type",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontFamily: "Montserrat" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const { errorAlert } = useContext<IAlertContext>(AlertContext);
  const { setIsLoader } = useContext<ILoader>(LoaderContext);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [tableData, setTableData] =
    useState<IpaginateResponse<IActivity> | null>(null);

  const fetchActivities = useCallback(
    async (pagination: IAPIListingQuery) => {
      setIsLoader(true);

      const response = await getAllActivitiesWithPagination(errorAlert, {
        ...pagination,
      });
      setTableData(response);
      setIsLoader(false);
    },
    [errorAlert, setIsLoader],
  );

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchActivities({
      page: page + 1,
      limit: rowsPerPage,
      sort: property,
      order: isAsc ? "asc" : "desc",
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchActivities({
      page: newPage + 1,
      limit: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchActivities({
      page: 1,
      limit: +event.target.value,
    });
  };

  const handleEditClick = (id: string) => {
    navigate(`/activity-form/${id}`);
  };
  const handleDeleteClick = () => {
    setOpen(true);
  };
  const handleDeleteClose = () => {
    setOpen(false);
  };
  const handleAddActivity = () => {
    navigate("/activity-form");
  };
  useEffect(() => {
    if (window.self === window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
      fetchActivities({
        page: page + 1,
        limit: rowsPerPage,
      });
    } else {
      navigate("/activitiess");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "15px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#0063ab",
            color: "#FFFFFF",
            fontFamily: "Montserrat",
          }}
          onClick={() => handleAddActivity()}
        >
          Add Activity
        </Button>
      </div>
      <Paper
        sx={{
          width: "calc(100% - 20px)",
          mb: 2,
          margin: "10px",
          padding: "10px",
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {tableData?.result?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row._id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ fontFamily: "Montserrat" }}
                    >
                      {row._id}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Montserrat" }}>
                      {row.name}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Montserrat" }}>
                      {row.gradeBands.map((item) => item?.value).join(", ")}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Montserrat" }}>
                      {row.type?.value}
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Montserrat" }}>
                      {row.subType?.value}
                    </TableCell>
                    <TableCell>
                      <SvgIcon
                        component={EditIcon}
                        onClick={() => handleEditClick(row._id)}
                        inheritViewBox
                        style={{ marginRight: "12px" }}
                      />
                      <SvgIcon
                        component={DeleteOutlineOutlinedIcon}
                        onClick={() => handleDeleteClick()}
                        inheritViewBox
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 200, 300, 400, 500, 600, 700, 800]}
          component="div"
          count={tableData?.totalElements ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            fontFamily: "Montserrat",
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select, & .MuiTablePagination-actions":
              {
                fontFamily: "Montserrat",
              },
          }}
        />
      </Paper>
      <Dialog
        open={open}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontFamily: "montserrat" }}
          >
            Are you sure you want to delete this activity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} sx={{ fontFamily: "montserrat" }}>
            No
          </Button>
          <Button
            onClick={handleDeleteClose}
            autoFocus
            sx={{ fontFamily: "montserrat" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
