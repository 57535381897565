import { AxiosError } from "axios";
import errorHandling from "../utils/errorHandling";
import BASE_API from "./axios";

async function getAllActivities(
  errorAlert: (message: string) => void,
): Promise<IActivity[] | null> {
  try {
    const response = await BASE_API.get<IActivity[]>("/activity");
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function getAllUserActivities(
  errorAlert: (message: string) => void,
  userId: number,
  isdisplayunrelated: boolean,
): Promise<IActivity[] | IThinkificUserActivity | null> {
  try {
    const response = await BASE_API.get<IActivity[] | IThinkificUserActivity>(
      `/activity/user/${userId}`,
      { headers: { isdisplayunrelated } },
    );
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function createActivity(
  errorAlert: (message: string) => void,
  formData: FormData,
): Promise<IActivity | null> {
  try {
    const response = await BASE_API.post<IActivity>("/activity", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return null;
  }
}

async function updateActivity(
  errorAlert: (message: string) => void,
  activityId: string,
  formData: FormData,
): Promise<IActivityApiResponse | null> {
  try {
    const response = await BASE_API.put<IActivityApiResponse>(
      `/activity/${activityId}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      },
    );

    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function searchActivities(
  errorAlert: (message: string) => void,
  searchParams: SearchParams,
): Promise<IActivity[] | IThinkificUserActivity | null> {
  try {
    const response = await BASE_API.post<IActivity[]>(
      "/activity/search",
      searchParams,
    );
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function searchUserActivities(
  errorAlert: (message: string) => void,
  searchParams: SearchParams,
  userId: number,
  isdisplayunrelated: boolean,
): Promise<IActivity[] | null> {
  try {
    const response = await BASE_API.post<IActivity[]>(
      `/activity/search/${userId}`,
      searchParams,
      { headers: { isdisplayunrelated } },
    );
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function searchUserActivitiesReturnCount(
  errorAlert: (message: string) => void,
  searchParams: SearchParams,
): Promise<ISearchActivityCount | null> {
  try {
    const response = await BASE_API.post<ISearchActivityCount>(
      "/activity/searchActivitiesCount",
      searchParams,
    );
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function getAllSavedActivities(
  errorAlert: (message: string) => void,
  userId: number,
): Promise<IActivity[] | null> {
  try {
    const response = await BASE_API.get<IActivity[]>(
      `/activity/saved/${userId}`,
    );
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function saveActivityForUser(
  errorAlert: (message: string) => void,
  userId: number,
  activity: string,
  isSave: boolean,
): Promise<IActivity[] | null> {
  try {
    const response = await BASE_API.post<IActivity[]>(
      `/activity/save/${userId}`,
      {
        activity,
        isSave,
      },
    );
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function getAllActivitiesWithPagination(
  errorAlert: (message: string) => void,
  pagination?: IAPIListingQuery,
): Promise<IpaginateResponse<IActivity> | null> {
  try {
    const response = await BASE_API.get<IpaginateResponse<IActivity>>(
      "/activity/pagination",
      {
        params: pagination,
      },
    );
    return response.data;
  } catch (err: unknown) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

export {
  getAllActivities,
  getAllUserActivities,
  createActivity,
  updateActivity,
  searchActivities,
  searchUserActivities,
  getAllSavedActivities,
  saveActivityForUser,
  getAllActivitiesWithPagination,
  searchUserActivitiesReturnCount,
};
