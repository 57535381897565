import { CloudUpload } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import EditSingleStyles from "../../../styles/editSingleActivity";

interface SingleFileUploaderProps {
  onFileUpload: (data: IUploadedPreview[]) => void;
  isFileUploaderToOpen: boolean;
  uploadedDataWithPreview: IUploadedPreview[];
}

const SingleFileUploader: React.FC<SingleFileUploaderProps> = ({
  onFileUpload,
  isFileUploaderToOpen = false,
  uploadedDataWithPreview,
}) => {
  const fileInputUploader = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isFileUploaderToOpen && fileInputUploader.current) {
      fileInputUploader.current.click();
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const newPreviews = selectedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      onFileUpload([...uploadedDataWithPreview, ...newPreviews]);
      if (fileInputUploader.current) {
        fileInputUploader.current.files = null;
        fileInputUploader.current.value = "";
      }
    }
  };

  const handleRemoveFile = (file: File) => {
    const updatedPreviews = uploadedDataWithPreview.filter(
      (p) => p.file.name !== file.name && p.file.size !== file.size,
    );
    onFileUpload(updatedPreviews);
  };

  return (
    <Container
      sx={EditSingleStyles.singleFileUploadContainer}
      style={EditSingleStyles.singleFileUploadContainerInlineStyle}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <label htmlFor="file">
            <input
              id="file"
              ref={fileInputUploader}
              type="file"
              multiple
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              variant="outlined"
              component="span"
              style={EditSingleStyles.chooseFileTextStyle}
              startIcon={<CloudUpload />}
            >
              Choose files
            </Button>
          </label>
        </Grid>
        {uploadedDataWithPreview.length > 0 && (
          <Grid
            item
            xs={12}
            container
            direction="row"
            wrap="wrap"
            sx={EditSingleStyles.uploadedDiagramMainContainerStyle}
          >
            <Typography
              variant="h6"
              width="100%"
              color="textPrimary"
              sx={EditSingleStyles.diagramModalActivityTitleStyle}
            >
              Selected files:
            </Typography>
            {uploadedDataWithPreview.map(({ preview, file }) => (
              <Grid
                item
                xs={11}
                md={3}
                sx={EditSingleStyles.uploadedPreviewGridStyle}
                key={file.name + file.lastModified}
              >
                <img
                  src={preview}
                  alt={`preview-${file.name}`}
                  className="preview-image"
                />
                <Button
                  variant="contained"
                  color="error"
                  className="uploaded-preview-delete-btn"
                  onClick={() => handleRemoveFile(file)}
                >
                  <CloseIcon
                    sx={EditSingleStyles.uploadImagePreviewDeleteIconStyle}
                  />
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SingleFileUploader;
