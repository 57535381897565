import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createActivity, updateActivity } from "../../../APIs/activitiesApi";
import { getAllMasterData } from "../../../APIs/masterDataApi";
import { fetchActivityData } from "../../../APIs/viewSingleActivityApi";
import AlertContext from "../../../context/alertContext";
import LoaderContext from "../../../context/loaderContext";
import {
  IActivityKeys,
  IActivityMasterKeys,
  IActivityTabKeys,
} from "../../../enums/ActivityKeysEnum";
import ILocalStorageEnum from "../../../enums/LocalStorageEnum";
import EditSingleStyles from "../../../styles/editSingleActivity";
import TabComponent from "../richtext/TabComponent";
import activityFormInitialData from "./create-edit-single-activity-form";
import "./create-edit-single-activity.scss";
import DigramModal, { ConfirmationDialog } from "./diagrams-modal";
import ErrorUI from "./error-ui";
import {
  validateInclusionGuideCrosslink,
  validateName,
  validateSubStandards,
  validateVideoData,
} from "./validations";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FieldLabel = ({
  title,
  isRequired = false,
}: {
  title: string;
  isRequired: boolean;
}) => (
  <Typography variant="subtitle2" align="left" sx={EditSingleStyles.typoLabel}>
    {isRequired && <span className="mandatory">* </span>}
    {title}
  </Typography>
);

export default function SingleActivityForm() {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const [activityFormData, setActivityFormData] = useState<IActivityData>({
    ...activityFormInitialData,
  });
  const [formHandlers, setFormHandlers] = useState<{
    isActivityExists: boolean;
    isEdited: boolean;
    isServerError: boolean;
    serverErrorMsg: string;
  }>({
    isActivityExists: true,
    isEdited: false,
    isServerError: false,
    serverErrorMsg: "",
  });
  const [activityData, setActivityData] = useState<IActivity | null>(null);

  const { successAlert, errorAlert } = useContext<IAlertContext>(AlertContext);
  const { setIsLoader } = useContext<ILoader>(LoaderContext);

  const handleFormStateChange = useCallback(
    (
      key: IActivityKeys | string,
      updatedFields: Partial<
        | IFormField
        | IFormFieldBaseResponse
        | IFormFieldBaseResponseArray
        | IFormFieldBoolean
        | IFormFieldSubType
        | IFormFileUploadMedia
        | IFormOptions
        | IFormTabField
      >,
    ) => {
      if (!formHandlers.isEdited) {
        setFormHandlers((prev) => ({ ...prev, isEdited: true }));
      }
      setActivityFormData((prev) => ({
        ...prev,
        [key]: {
          ...prev[key as keyof typeof prev],
          ...updatedFields,
        },
      }));
    },
    [formHandlers.isEdited],
  );

  const handleValueChange = (
    key: IActivityKeys,
    data: boolean | string | string[] | BaseResponse | BaseResponse[] | null,
  ) => {
    const { spaceRequirement, numberOfPlayers } = IActivityKeys;
    handleFormStateChange(key, {
      value: data,
      ...([spaceRequirement, numberOfPlayers].includes(key)
        ? { isValid: Boolean(data) }
        : {}),
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    handleFormStateChange("tabData", { tabId: newValue });
    setFormHandlers((prev) => ({ ...prev, isEdited: false }));
  };

  const handleTypeChange = (newValue: BaseResponse | null) => {
    const { subType } = activityFormData;

    const currentSubTypeOptions: BaseResponse[] = newValue
      ? subType.options.filter(
          (item) => item.reference?.includes(newValue?._id),
        )
      : subType.options;

    const newSubTypeValue = subType.value?.reference?.includes(
      newValue?._id ?? "",
    )
      ? subType.value
      : null;

    setActivityFormData((prev) => ({
      ...prev,
      type: {
        ...activityFormData.type,
        value: newValue,
        isValid: Boolean(newValue),
      },
      subType: {
        ...activityFormData.subType,
        value: newSubTypeValue,
        displayOptions: currentSubTypeOptions,
      },
    }));

    if (!formHandlers.isEdited) {
      setFormHandlers((prev) => ({ ...prev, isEdited: true }));
    }
  };

  useEffect(() => {
    if (window.self !== window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchedActivityData: IActivityData = JSON.parse(
      JSON.stringify(activityFormInitialData),
    );

    const fetchMasterData = async () => {
      try {
        const masterData = await getAllMasterData(errorAlert);
        if (masterData) {
          Object.keys(IActivityMasterKeys).forEach((key) => {
            const type =
              IActivityMasterKeys[key as keyof typeof IActivityMasterKeys];
            const filteredData = masterData.filter(
              (item) => item.type === type,
            );

            fetchedActivityData[key as IActivityMasterKeys].options =
              filteredData;
            if (key === IActivityMasterKeys.subType)
              fetchedActivityData[key].displayOptions = filteredData;
          });
        }
      } catch (error) {
        errorAlert("Error fetching master data");
        setFormHandlers((prev) => ({
          ...prev,
          isServerError: true,
          serverErrorMsg: "Error: failed to fetch master data",
        }));
      }
    };

    const fetchActivityDataById = async (activityId: string) => {
      try {
        const activityDetails = await fetchActivityData(activityId);
        if (activityDetails) {
          setActivityData(activityDetails);

          Object.keys(activityFormData).forEach((key) => {
            const filteredData =
              activityDetails[key as keyof typeof activityData];

            if (key === IActivityKeys.subStandardsAlignments) {
              fetchedActivityData[key].value = [...filteredData].join(", ");
            } else if (key !== "tabData") {
              fetchedActivityData[
                key as IActivityKeys | IActivityTabKeys
              ].value = filteredData;
            }
          });

          if (fetchedActivityData.type.value) {
            fetchedActivityData.subType.displayOptions =
              fetchedActivityData.subType.options.filter((item) => {
                return item.reference?.includes(
                  fetchedActivityData.type.value?._id ?? "",
                );
              });
          }
        }
      } catch (error) {
        errorAlert("Error fetching activity data");
        setFormHandlers((prev) => ({
          ...prev,
          isActivityExists: false,
          isServerError: true,
          serverErrorMsg: "Error: failed to fetch activity data",
        }));
      }
    };

    const fetchBoth = async () => {
      await fetchMasterData();
      if (id) {
        await fetchActivityDataById(id);
      }
      setActivityFormData(fetchedActivityData);
      setIsLoader(false);
    };

    setIsLoader(true);
    fetchBoth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAlert, id]);

  const currentTab = Object.values(activityFormData).find(
    (item) => item.tabId === activityFormData.tabData.tabId,
  );

  const handleTabDataChange = (newContent: string) => {
    handleFormStateChange(currentTab.key, {
      value: newContent,
      isValid: Boolean(
        newContent && newContent !== "<p><br></p>" && newContent !== "<p></p>",
      ),
    });
  };

  const handleModalToggle = () => {
    handleFormStateChange(IActivityKeys.diagram, {
      isModalOpen: !activityFormData.diagram.isModalOpen,
    });
  };

  const handleUploadedDataWithPreviews = (data: IUploadedPreview[]) => {
    handleFormStateChange(IActivityKeys.diagram, {
      uploadedData: data.map((item) => item.file),
      uploadedDataWithPreviews: data,
    });
  };

  const handleDeleteUploadDigramFromPreview = (file: File) => {
    const newUploadedPreviews =
      activityFormData.diagram.uploadedDataWithPreviews.filter(
        (item) => item.file !== file,
      );
    handleUploadedDataWithPreviews(newUploadedPreviews);
  };

  const handleConfirmToDeleteModalCloseInPreview = (res: boolean) => {
    if (res) activityFormData.diagram.methodForPreviewRemoval?.();
    handleFormStateChange(IActivityKeys.diagram, {
      isConfirmModalOpen: false,
      methodForPreviewRemoval: undefined,
    });
    if (!res) {
      setFormHandlers((prev) => ({ ...prev, isEdited: false }));
    }
  };

  const handleDeleteDiagramDataFromPreview = (indexToDelete: number) => {
    const remaingImages = activityFormData.diagram.value.filter(
      (_, index) => index !== indexToDelete,
    );
    handleValueChange(IActivityKeys.diagram, remaingImages);
  };

  const previewDiagramsCollection = [
    ...activityFormData.diagram.uploadedDataWithPreviews.map((item) => ({
      imageUrl: item.preview,
      methodToDelete: () => handleDeleteUploadDigramFromPreview(item.file),
    })),
    ...activityFormData.diagram.value.map((item, index) => ({
      imageUrl: item,
      methodToDelete: () => handleDeleteDiagramDataFromPreview(index),
    })),
  ].splice(0, 3);

  const handleAllRadioChange = (
    field: IActivityKeys,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    const result = value === "Yes" || value === "True";

    const { isFreeSample, hasSpanishTranslation, isEquipmentRequired } =
      IActivityKeys;
    if (
      [isFreeSample, hasSpanishTranslation, isEquipmentRequired].includes(field)
    )
      handleValueChange(field, result);
  };

  const handleAllCheckboxChange = (
    field: IActivityKeys,
    item: BaseResponse,
  ) => {
    const getUpdatedSelections = (items: BaseResponse[]): BaseResponse[] => {
      return items.some((selected) => selected._id === item._id)
        ? items.filter((selected) => selected._id !== item._id)
        : [...items, item];
    };

    if (field === IActivityKeys.gradeBands) {
      const data: BaseResponse[] = getUpdatedSelections(
        activityFormData.gradeBands.value,
      );
      handleFormStateChange(IActivityKeys.gradeBands, {
        value: data,
        isValid: Boolean(data.length),
      });
    } else if (field === IActivityKeys.standardsAlignments) {
      handleValueChange(
        IActivityKeys.standardsAlignments,
        getUpdatedSelections(activityFormData.standardsAlignments.value),
      );
    } else if (field === IActivityKeys.selCompetencies) {
      handleValueChange(
        IActivityKeys.selCompetencies,
        getUpdatedSelections(activityFormData.selCompetencies.value),
      );
    }
  };

  const handleTextFieldChange = (
    field: IActivityKeys,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    const trimmedValue = value.trim();

    if (field === IActivityKeys.inclusionGuideCrosslink) {
      handleFormStateChange(field, {
        value,
        ...validateInclusionGuideCrosslink(value),
      });
    } else if (field === IActivityKeys.name) {
      handleFormStateChange(field, {
        value,
        ...validateName(value),
      });
    } else if (
      [IActivityKeys.videoEnglish, IActivityKeys.videoSpanish].includes(field)
    ) {
      handleFormStateChange(field, {
        value: trimmedValue,
        ...validateVideoData(trimmedValue),
      });
    } else if (field === IActivityKeys.subStandardsAlignments) {
      handleFormStateChange(field, {
        value,
        ...validateSubStandards(value),
      });
    }
  };

  const validateForm = (): string[] => {
    const hasErrors: string[] = [];

    const validationResults: Record<
      string,
      {
        isValid: boolean;
        validationErrorMsg?: string;
      } | null
    > = {
      name: null,
      inclusionGuideCrosslink: null,
      type: null,
      numberOfPlayers: null,
      spaceRequirement: null,
      subStandardsAlignments: null,
      organization: null,
      instructions: null,
      gradeBands: null,
      skillsAndConcepts: null,
      videoEnglish: null,
      videoSpanish: null,
    };

    Object.values(activityFormData).forEach((item) => {
      const { key, value } = item;

      switch (key) {
        case IActivityKeys.name: {
          const result = validateName(value);
          validationResults.name = result;
          if (!result.isValid) hasErrors.push(key);
          break;
        }
        case IActivityKeys.inclusionGuideCrosslink: {
          const result = validateInclusionGuideCrosslink(value);
          validationResults.inclusionGuideCrosslink = result;
          if (!result.isValid) hasErrors.push(key);
          break;
        }
        case IActivityKeys.type:
        case IActivityKeys.numberOfPlayers:
        case IActivityKeys.spaceRequirement: {
          const result = Boolean(value);
          validationResults[key] = { isValid: result };
          if (!result) hasErrors.push(key);
          break;
        }
        case IActivityKeys.subStandardsAlignments: {
          const result = validateSubStandards(value);
          validationResults.subStandardsAlignments = result;
          if (!result.isValid) hasErrors.push(key);
          break;
        }
        case IActivityTabKeys.organization:
        case IActivityTabKeys.instructions: {
          const result = Boolean(
            value && value !== "<p><br></p>" && value !== "<p></p>",
          );
          validationResults[key] = { isValid: result };

          if (!result) hasErrors.push(key);
          break;
        }
        case IActivityKeys.gradeBands:
        case IActivityKeys.skillsAndConcepts: {
          const result = Boolean(value.length);
          validationResults[key] = { isValid: result };
          if (!result) hasErrors.push(key);
          break;
        }
        case IActivityKeys.videoEnglish:
        case IActivityKeys.videoSpanish: {
          if (value) {
            const result = validateVideoData(value);
            validationResults[key] = result;
            if (!result.isValid) hasErrors.push(key);
          }
          break;
        }
        default:
      }
    });

    setActivityFormData((prev) => ({
      ...prev,
      name: {
        ...prev.name,
        ...validationResults.name,
      },
      inclusionGuideCrosslink: {
        ...prev.inclusionGuideCrosslink,
        ...validationResults.inclusionGuideCrosslink,
      },
      type: {
        ...prev.type,
        ...validationResults.type,
      },
      numberOfPlayers: {
        ...prev.numberOfPlayers,
        ...validationResults.numberOfPlayers,
      },
      spaceRequirement: {
        ...prev.spaceRequirement,
        ...validationResults.spaceRequirement,
      },
      subStandardsAlignments: {
        ...prev.subStandardsAlignments,
        ...validationResults.subStandardsAlignments,
      },
      organization: {
        ...prev.organization,
        ...validationResults.organization,
      },
      instructions: {
        ...prev.instructions,
        ...validationResults.instructions,
      },
      gradeBands: {
        ...prev.gradeBands,
        ...validationResults.gradeBands,
      },
      skillsAndConcepts: {
        ...prev.skillsAndConcepts,
        ...validationResults.skillsAndConcepts,
      },
      videoEnglish: {
        ...prev.videoEnglish,
        ...validationResults.videoEnglish,
      },
      videoSpanish: {
        ...prev.videoSpanish,
        ...validationResults.videoSpanish,
      },
    }));

    return hasErrors;
  };

  const handleSubmit = async () => {
    if (id && !formHandlers.isEdited) {
      errorAlert("No changes has been made since it was last submitted");
      return;
    }

    const hasErrors = validateForm();
    if (hasErrors.length > 0) {
      errorAlert("Please clear all the mentioned error before submit");
      return;
    }

    try {
      const extractedDataFromFormData = Object.entries(activityFormData).reduce(
        (acc, [key, value]) => {
          if (key === "tabData") return acc;

          if (key === IActivityKeys.subStandardsAlignments) {
            acc[value.key] = value.value
              .split(",")
              .map((item: string) => item.trim());
            return acc;
          }
          if (
            [
              IActivityTabKeys.equipmentDescription,
              IActivityTabKeys.organization,
              IActivityTabKeys.instructions,
              IActivityTabKeys.teachingSuggestions,
              IActivityTabKeys.variations,
              IActivityTabKeys.differentiation,
              IActivityTabKeys.selReinforcement,
              IActivityTabKeys.inclusionAdaptation,
              IActivityTabKeys.virtualAdaption,
            ].includes(key as IActivityTabKeys) &&
            (value.value === "<p><br></p>" || value.value === "<p></p>")
          ) {
            acc[value.key] = "";
            return acc;
          }
          acc[value.key] = value.value;
          return acc;
        },
        {} as {
          [key: string]:
            | boolean
            | string
            | string[]
            | BaseResponse
            | BaseResponse[];
        },
      );

      const updatedData: Partial<IActivity> = {
        ...activityData,
        ...extractedDataFromFormData,
        diagram: [
          ...activityFormData.diagram.value,
          ...(activityFormData.diagram.uploadedData.length > 0
            ? activityFormData.diagram.uploadedData.map((file) =>
                URL.createObjectURL(file),
              )
            : []),
        ],
      };

      const updatedDataAPI: {
        [key: string]: string | number | boolean | string[] | string;
      } = {};

      Object.entries(updatedData).forEach(([key, value]) => {
        if (
          [
            IActivityKeys.name,
            IActivityKeys.type,
            IActivityKeys.subType,
            IActivityKeys.numberOfPlayers,
            IActivityKeys.spaceRequirement,
            IActivityKeys.progressionLevel,
            IActivityKeys.videoEnglish,
            IActivityKeys.videoSpanish,
            IActivityKeys.inclusionGuideCrosslink,
            IActivityTabKeys.organization,
            IActivityTabKeys.equipmentDescription,
            IActivityTabKeys.instructions,
            IActivityTabKeys.teachingSuggestions,
            IActivityTabKeys.variations,
            IActivityTabKeys.differentiation,
            IActivityTabKeys.selReinforcement,
            IActivityTabKeys.inclusionAdaptation,
            IActivityTabKeys.virtualAdaption,
          ].includes(key as IActivityKeys | IActivityTabKeys)
        ) {
          updatedDataAPI[key] =
            value && typeof value === "object" && "_id" in value
              ? (value as BaseResponse)._id
              : (value as string | number | boolean);
        } else if (
          [
            IActivityKeys.gradeBands,
            IActivityKeys.standardsAlignments,
            IActivityKeys.subStandardsAlignments,
            IActivityKeys.selCompetencies,
            IActivityKeys.skillsAndConcepts,
            IActivityKeys.equipment,
          ].includes(key as IActivityKeys)
        ) {
          updatedDataAPI[key] = (value as BaseResponse[]).map((item) =>
            typeof item === "object" && "_id" in item
              ? item._id
              : (item as unknown as string),
          );
        } else if (key === IActivityKeys.diagram) {
          updatedDataAPI[key] = (value as string[]).filter(
            (item) => !item.includes("blob"),
          );
        } else {
          updatedDataAPI[key] = value as string | string[] | number | boolean;
        }
      });

      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          ...updatedDataAPI,
          isFreeSample: updatedDataAPI.isFreeSample ? "true" : "false",
          hasSpanishTranslation: updatedDataAPI.hasSpanishTranslation
            ? "true"
            : "false",
          isEquipmentRequired: updatedDataAPI.isEquipmentRequired
            ? "true"
            : "false",
        }),
      );

      activityFormData.diagram.uploadedData.forEach((item) => {
        formData.append(IActivityKeys.diagram, item);
      });

      if (id) {
        const updatedActivity = await updateActivity(errorAlert, id, formData);
        if (updatedActivity) {
          setActivityData(updatedActivity.activity);
          setActivityFormData((prev) => ({
            ...prev,
            diagram: {
              ...prev.diagram,
              uploadedData: [],
              uploadedDataWithPreviews: [],
              value: updatedActivity.activity.diagram,
            },
          }));
          successAlert("Activity updated successfully");
        }
      } else {
        const createdActivity = await createActivity(errorAlert, formData);
        if (createdActivity) {
          successAlert("Activity created successfully");
          navigate(`/activity-form/${createdActivity._id}`);
        }
      }

      setFormHandlers((prev) => ({ ...prev, isEdited: false }));
    } catch (error) {
      const response = id
        ? "Error updating activity"
        : "Error creating activity";
      errorAlert(response);
      setFormHandlers((prev) => ({ ...prev, isServerError: true }));
    }
  };

  // when id is given but no data is found
  if (id && !formHandlers.isActivityExists) {
    return (
      <ErrorUI
        title="No Activity Found."
        description="There is no data found with this id"
      />
    );
  }

  if (formHandlers.isServerError) {
    return (
      <ErrorUI
        title={
          <>
            Internal Server Error. <br /> Please try again after some time
          </>
        }
        description={formHandlers.serverErrorMsg}
      />
    );
  }

  return (
    <Box className="autoStyle" sx={EditSingleStyles.boxContainer}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={EditSingleStyles.mainTitle}>
          {activityData ? "Edit Activity" : "Create New Activity"}
        </Typography>
      </Grid>
      <Paper sx={EditSingleStyles.wholePaperPadding}>
        <Grid sx={EditSingleStyles.firstGridSub1} container>
          <Grid
            item
            xs={12}
            sm={6}
            md={9}
            className="gridContainer"
            sx={EditSingleStyles.firstGrid}
          >
            <Grid container className="borderBottomStyle">
              <Grid item xs={12} sm={6} md={4}>
                <FieldLabel
                  title={activityFormData.name.title}
                  isRequired={activityFormData.name.isRequired}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  className="custom-width"
                  value={activityFormData.name.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextFieldChange(IActivityKeys.name, e);
                  }}
                  error={!activityFormData.name.isValid}
                  helperText={
                    !activityFormData.name.isValid && (
                      <span className="custom-validation-error-text">
                        {activityFormData.name.validationErrorMsg}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={EditSingleStyles.gridAlign}
                className="checkbox"
              >
                <FieldLabel
                  title={activityFormData.isFreeSample.title}
                  isRequired={activityFormData.isFreeSample.isRequired}
                />
                <FormControl component="fieldset" variant="standard">
                  <FormGroup className="radioLabels">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={activityFormData.isFreeSample.value ? "Yes" : "No"}
                      onChange={(event) =>
                        handleAllRadioChange(IActivityKeys.isFreeSample, event)
                      }
                      row
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FieldLabel
                  title={activityFormData.type.title}
                  isRequired={activityFormData.type.isRequired}
                />
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    options={activityFormData.type.options}
                    className="custom-width"
                    value={activityFormData.type.value}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    getOptionLabel={(option) => option.value}
                    defaultValue={activityFormData.type.value || null}
                    onChange={(_, newValue) => {
                      handleTypeChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!activityFormData.type.isValid}
                        {...params}
                      />
                    )}
                  />
                  {!activityFormData.type.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.type.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FieldLabel
                  title={activityFormData.subType.title}
                  isRequired={activityFormData.subType.isRequired}
                />
                <Autocomplete
                  id="tags-outlined"
                  disabled={Boolean(!activityFormData.type.value)}
                  value={activityFormData.subType.value}
                  options={activityFormData.subType.displayOptions}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  className="custom-width"
                  getOptionLabel={(option: BaseResponse) => option.value}
                  onChange={(_, newValue) => {
                    handleValueChange(IActivityKeys.subType, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  classes={{
                    option: "autocomplete-option",
                    noOptions: "autocomplete-option",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={EditSingleStyles.gridAlign}
                className="checkbox"
              >
                <FieldLabel
                  title={activityFormData.gradeBands.title}
                  isRequired={activityFormData.gradeBands.isRequired}
                />

                <FormControl
                  sx={EditSingleStyles.checkBoxStyle}
                  component="fieldset"
                  variant="standard"
                  size="small"
                >
                  {activityFormData.gradeBands.options.map((band) => (
                    <FormControlLabel
                      sx={EditSingleStyles.checkboxLabelStyle}
                      control={
                        <Checkbox
                          size="small"
                          checked={activityFormData.gradeBands.value.some(
                            (selected) => selected._id === band._id,
                          )}
                          onChange={() =>
                            handleAllCheckboxChange(
                              IActivityKeys.gradeBands,
                              band,
                            )
                          }
                          name={band.value}
                        />
                      }
                      label={<span className="formLabel">{band.value}</span>}
                      key={band._id}
                    />
                  ))}
                  {!activityFormData.gradeBands.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.gradeBands.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="borderBottomStyle">
              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <FieldLabel
                  title={activityFormData.numberOfPlayers.title}
                  isRequired={activityFormData.numberOfPlayers.isRequired}
                />
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    value={activityFormData.numberOfPlayers.value}
                    options={activityFormData.numberOfPlayers.options}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    defaultValue={
                      activityFormData?.numberOfPlayers.value || null
                    }
                    onChange={(_, newValue) => {
                      handleValueChange(
                        IActivityKeys.numberOfPlayers,
                        newValue,
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!activityFormData.numberOfPlayers.isValid}
                        {...params}
                      />
                    )}
                  />
                  {!activityFormData.numberOfPlayers.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.numberOfPlayers.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <FieldLabel
                  title={activityFormData.spaceRequirement.title}
                  isRequired={activityFormData.spaceRequirement.isRequired}
                />
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    value={activityFormData.spaceRequirement.value}
                    options={activityFormData.spaceRequirement.options}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    defaultValue={
                      activityFormData.spaceRequirement.value || null
                    }
                    onChange={(_, newValue) => {
                      handleValueChange(
                        IActivityKeys.spaceRequirement,
                        newValue,
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!activityFormData.spaceRequirement.isValid}
                        {...params}
                      />
                    )}
                  />
                  {!activityFormData.spaceRequirement.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.spaceRequirement.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="borderBottomStyle">
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={EditSingleStyles.gridAlign}
                className="checkbox"
              >
                <FieldLabel
                  title={activityFormData.standardsAlignments.title}
                  isRequired={activityFormData.standardsAlignments.isRequired}
                />

                <FormControl
                  sx={EditSingleStyles.checkBoxStyle}
                  component="fieldset"
                  variant="standard"
                  size="small"
                  fullWidth
                >
                  {activityFormData.standardsAlignments.options.map(
                    (standard) => (
                      <FormControlLabel
                        sx={EditSingleStyles.checkboxLabelStyle}
                        control={
                          <Checkbox
                            size="small"
                            checked={activityFormData.standardsAlignments.value.some(
                              (selected) => selected._id === standard._id,
                            )}
                            onChange={() =>
                              handleAllCheckboxChange(
                                IActivityKeys.standardsAlignments,
                                standard,
                              )
                            }
                            name={standard.value}
                          />
                        }
                        label={
                          <span className="formLabel">{standard.value}</span>
                        }
                        key={standard._id}
                      />
                    ),
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <FieldLabel
                  title={activityFormData.subStandardsAlignments.title}
                  isRequired={
                    activityFormData.subStandardsAlignments.isRequired
                  }
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  error={!activityFormData.subStandardsAlignments.isValid}
                  className="custom-width"
                  value={activityFormData.subStandardsAlignments.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextFieldChange(
                      IActivityKeys.subStandardsAlignments,
                      e,
                    );
                  }}
                  helperText={
                    !activityFormData.subStandardsAlignments.isValid && (
                      <span className="custom-validation-error-text">
                        {
                          activityFormData.subStandardsAlignments
                            .validationErrorMsg
                        }
                      </span>
                    )
                  }
                  placeholder="Enter multiple values with separated by comma"
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="checkbox">
                <FieldLabel
                  title={activityFormData.selCompetencies.title}
                  isRequired={activityFormData.selCompetencies.isRequired}
                />
                <FormControl
                  sx={EditSingleStyles.checkBoxStyle}
                  component="fieldset"
                  variant="standard"
                >
                  {activityFormData.selCompetencies.options.map(
                    (selCompetency) => (
                      <FormControlLabel
                        sx={EditSingleStyles.checkboxLabelStyle}
                        control={
                          <Checkbox
                            size="small"
                            checked={activityFormData.selCompetencies.value.some(
                              (selected) => selected._id === selCompetency._id,
                            )}
                            onChange={() =>
                              handleAllCheckboxChange(
                                IActivityKeys.selCompetencies,
                                selCompetency,
                              )
                            }
                            name={selCompetency.value}
                          />
                        }
                        label={
                          <span className="formLabel">
                            {selCompetency.value}
                          </span>
                        }
                        key={selCompetency._id}
                      />
                    ),
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="borderBottomStyle">
              <Grid item xs={12} sm={6} md={4}>
                <FieldLabel
                  title={activityFormData.progressionLevel.title}
                  isRequired={activityFormData.progressionLevel.isRequired}
                />
                <Autocomplete
                  disablePortal
                  id="type-autocomplete"
                  value={activityFormData.progressionLevel.value}
                  options={activityFormData.progressionLevel.options}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  className="custom-width"
                  getOptionLabel={(option) => option.value}
                  defaultValue={activityFormData.progressionLevel.value || null}
                  onChange={(_, newValue) => {
                    handleValueChange(IActivityKeys.progressionLevel, newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FieldLabel
                  title={activityFormData.skillsAndConcepts.title}
                  isRequired={activityFormData.skillsAndConcepts.isRequired}
                />
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="tags-outlined"
                    value={activityFormData.skillsAndConcepts.value}
                    options={activityFormData.skillsAndConcepts.options}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    className="custom-width"
                    getOptionLabel={(option: BaseResponse) => option.value}
                    defaultValue={
                      activityFormData.skillsAndConcepts.value || []
                    }
                    onChange={(_, newValue) => {
                      handleFormStateChange(IActivityKeys.skillsAndConcepts, {
                        value: newValue,
                        isValid: Boolean(newValue.length),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!activityFormData.skillsAndConcepts.isValid}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    classes={{
                      option: "autocomplete-option",
                      noOptions: "autocomplete-option",
                    }}
                    ChipProps={{
                      className: "autocomplete-chip",
                      variant: "filled",
                      deleteIcon: (
                        <CancelRoundedIcon className="autocomplete-delete-icon" />
                      ),
                    }}
                  />
                  {!activityFormData.skillsAndConcepts.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.skillsAndConcepts.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FieldLabel
                  title={activityFormData.equipment.title}
                  isRequired={activityFormData.equipment.isRequired}
                />
                <Autocomplete
                  multiple
                  id="equipment-autocomplete"
                  options={activityFormData.equipment.options}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  className="custom-width"
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.value}
                  value={activityFormData.equipment.value || []}
                  onChange={(e, newValue) => {
                    handleValueChange(IActivityKeys.equipment, newValue);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={EditSingleStyles.equipmentCheckboxList}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={EditSingleStyles.equipmentCheckboxListCheckBox}
                        checked={
                          selected ||
                          (activityFormData.equipment.value?.some(
                            (e) => e._id === option._id,
                          ) ??
                            false)
                        }
                      />
                      {option.value}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} />}
                  ChipProps={{
                    className: "autocomplete-chip",
                    variant: "filled",
                    deleteIcon: (
                      <CancelRoundedIcon className="autocomplete-delete-icon" />
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className="borderBottomStyles">
              <Grid item xs={12} sm={6} md={4}>
                <FieldLabel
                  title={activityFormData.inclusionGuideCrosslink.title}
                  isRequired={
                    activityFormData.inclusionGuideCrosslink.isRequired
                  }
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  error={!activityFormData.inclusionGuideCrosslink.isValid}
                  helperText={
                    !activityFormData.inclusionGuideCrosslink.isValid && (
                      <span className="custom-validation-error-text">
                        {
                          activityFormData.inclusionGuideCrosslink
                            .validationErrorMsg
                        }
                      </span>
                    )
                  }
                  variant="outlined"
                  className="custom-width"
                  value={activityFormData.inclusionGuideCrosslink.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextFieldChange(
                      IActivityKeys.inclusionGuideCrosslink,
                      event,
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <FieldLabel
                  title={activityFormData.hasSpanishTranslation.title}
                  isRequired={activityFormData.hasSpanishTranslation.isRequired}
                />
                <FormControl
                  sx={EditSingleStyles.radioLabels}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup className="radioLabels">
                    <RadioGroup
                      aria-labelledby="has-spanish-translation-group"
                      name="has-spanish-translation-group"
                      value={
                        activityFormData.hasSpanishTranslation.value
                          ? "True"
                          : "False"
                      }
                      onChange={(event) =>
                        handleAllRadioChange(
                          IActivityKeys.hasSpanishTranslation,
                          event,
                        )
                      }
                      row
                    >
                      <FormControlLabel
                        value="True"
                        control={<Radio />}
                        label="True"
                      />
                      <FormControlLabel
                        value="False"
                        control={<Radio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <FieldLabel
                  title={activityFormData.isEquipmentRequired.title}
                  isRequired={activityFormData.isEquipmentRequired.isRequired}
                />
                <FormControl
                  sx={EditSingleStyles.radioLabels}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup className="radioLabels">
                    <RadioGroup
                      aria-labelledby="equipment-required-group"
                      name="equipment-required-group"
                      value={
                        activityFormData.isEquipmentRequired.value
                          ? "True"
                          : "False"
                      }
                      onChange={(event) =>
                        handleAllRadioChange(
                          IActivityKeys.isEquipmentRequired,
                          event,
                        )
                      }
                      row
                    >
                      <FormControlLabel
                        value="True"
                        control={<Radio />}
                        label="True"
                      />
                      <FormControlLabel
                        value="False"
                        control={<Radio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridContainer">
            <Grid container>
              <Grid item xs={12}>
                <FieldLabel
                  title={activityFormData.videoEnglish.title}
                  isRequired={activityFormData.videoEnglish.isRequired}
                />

                <TextField
                  id="outlined-basic"
                  size="small"
                  error={!activityFormData.videoEnglish.isValid}
                  helperText={
                    !activityFormData.videoEnglish.isValid && (
                      <span className="custom-validation-error-text">
                        {activityFormData.videoEnglish.validationErrorMsg}
                      </span>
                    )
                  }
                  variant="outlined"
                  placeholder="Vimeo Id"
                  value={activityFormData.videoEnglish.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleTextFieldChange(IActivityKeys.videoEnglish, event)
                  }
                  sx={EditSingleStyles.videoTextFieldStyle}
                />
                {activityFormData.videoEnglish.value && (
                  <div className="videoFrame">
                    <iframe
                      src={`https://player.vimeo.com/video/${activityFormData.videoEnglish.value}?badge=0&vimeo_logo=false`}
                      allow="autoplay; fullscreen; picture-in-picture"
                      title={
                        activityData
                          ? `${activityData.name} - CATCH Physical Activity Demo Video`
                          : "CATCH Physical Activity Demo Video"
                      }
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <FieldLabel
                  title={activityFormData.videoSpanish.title}
                  isRequired={activityFormData.videoSpanish.isRequired}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  error={!activityFormData.videoSpanish.isValid}
                  helperText={
                    !activityFormData.videoSpanish.isValid && (
                      <span className="custom-validation-error-text">
                        {activityFormData.videoSpanish.validationErrorMsg}
                      </span>
                    )
                  }
                  variant="outlined"
                  className="custom-width"
                  placeholder="Vimeo Id"
                  value={activityFormData.videoSpanish.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleTextFieldChange(IActivityKeys.videoSpanish, event)
                  }
                  sx={EditSingleStyles.videoTextFieldStyle}
                />
                {activityFormData.videoSpanish.value && (
                  <div className="videoFrame">
                    <iframe
                      src={`https://player.vimeo.com/video/${activityFormData.videoSpanish.value}?badge=0&vimeo_logo=false`}
                      allow="autoplay; fullscreen; picture-in-picture"
                      title={
                        activityData
                          ? `${activityData.name} - CATCH Physical Activity Demo Video`
                          : "CATCH Physical Activity Demo Video "
                      }
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <FieldLabel
                  title={activityFormData.diagram.title}
                  isRequired={activityFormData.diagram.isRequired}
                />
                <DigramModal
                  handleValueChange={handleValueChange}
                  renderDiagrams={activityFormData.diagram.value}
                  isModalOpen={activityFormData.diagram.isModalOpen}
                  handleClose={handleModalToggle}
                  uploadedDataWithPreview={
                    activityFormData.diagram.uploadedDataWithPreviews
                  }
                  handleUploadedDataWithPreviews={
                    handleUploadedDataWithPreviews
                  }
                />
                <Grid
                  container
                  sx={EditSingleStyles.previewDiagramsStyle}
                  direction="row"
                  justifyContent="space-between"
                  rowGap={0.5}
                >
                  {previewDiagramsCollection.map((item) => (
                    <Grid
                      item
                      key={item.imageUrl}
                      xs={12}
                      md={5.9}
                      sx={{
                        background: item ? `url(${item.imageUrl})` : "#ccc",
                        ...EditSingleStyles.previewGridStyles,
                      }}
                      style={EditSingleStyles.previewGridInlineStyle}
                    >
                      <ToggleButton
                        size="small"
                        value="check"
                        color="error"
                        selected={false}
                        onChange={() => {
                          handleFormStateChange(IActivityKeys.diagram, {
                            isConfirmModalOpen: true,
                            methodForPreviewRemoval: item.methodToDelete,
                          });
                        }}
                        className="preview-delete-toggle-btn"
                      >
                        <DeleteIcon
                          sx={EditSingleStyles.previewDeleteIconStyle}
                        />
                      </ToggleButton>
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={12}
                    md={previewDiagramsCollection.length % 2 ? 5.9 : 12}
                    style={EditSingleStyles.previewGridInlineStyle}
                    onClick={handleModalToggle}
                  >
                    <Box sx={EditSingleStyles.previewUploadStyle}>
                      <CloudUploadIcon
                        sx={EditSingleStyles.uploadCloudIconStyle}
                      />
                      <Typography
                        variant="body1"
                        sx={EditSingleStyles.previewAndUploadTextStyle}
                      >
                        {activityFormData.diagram.value.length > 0
                          ? "Preview & Upload"
                          : "Upload Diagrams"}
                      </Typography>
                    </Box>
                  </Grid>

                  <ConfirmationDialog
                    isConfirmModalOpen={
                      activityFormData.diagram.isConfirmModalOpen
                    }
                    handleClose={handleConfirmToDeleteModalCloseInPreview}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className="gridContainer">
          <TabComponent
            value={activityFormData.tabData.tabId}
            handleChange={handleTabChange}
            tabData={currentTab.value}
            handleTabDataChange={handleTabDataChange}
            isEditing
          />
          <br />
          {!activityFormData.organization.isValid && (
            <span className="custom-validation-error-text">
              {activityFormData.organization.validationErrorMsg}
            </span>
          )}
          <br />
          {!activityFormData.instructions.isValid && (
            <span className="custom-validation-error-text">
              {activityFormData.instructions.validationErrorMsg}
            </span>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Button
            variant="contained"
            className="centerAlign"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
}
