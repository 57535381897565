import { Link } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";
import EditSingleStyles from "../../../styles/editSingleActivity";

export default function ErrorUI({
  title,
  description,
}: {
  title: string | React.ReactNode;
  description: string;
}) {
  return (
    <Box className="autoStyle" sx={EditSingleStyles.boxContainer}>
      <Paper sx={EditSingleStyles.errorUiStyles}>
        <Typography variant="h5" sx={EditSingleStyles.typoLabel}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={EditSingleStyles.typoLabel}
          color="error"
        >
          {description ?? ""}
        </Typography>
        <Link to="/" style={EditSingleStyles.ErrorUiGoBackStyke}>
          <Button variant="contained" className="centerAlign">
            Go Back
          </Button>
        </Link>
      </Paper>
    </Box>
  );
}
